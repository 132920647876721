html {
    scroll-behavior: smooth;
  }
.color-palette {
    list-style: none;
    padding: 0;
    margin: 0;
}
.color-shade {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}
.shade-color {
  width: 64px; 
  height: 64px; 
  background-color: black;
  border-radius: 50%;
  margin-bottom: 1px;
  position: relative;
  overflow: hidden;
}
.shade-color img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image maintains its aspect ratio */
}
.shade-name {
  display: block;
  text-align: center;
  font-size: 14px;
}
.color-input {
  margin-top: 10px;
  width: 100%;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.color-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.25);
}
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.7);
}
.modal-content {
  background-color: #fefefe;
  margin: 1% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 80%;
  max-width: 80%;
  border-radius: 10px;
}
.modal-img {
  width: 270px;
  height: auto;
  cursor: pointer;
  border: 0.5px solid lightgrey;
  margin: 10px;
}
#openModalBtn{
  cursor: pointer;
}
.selected-img-one{
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 10px;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.slideshow-container {
  /* position: relative; */
  overflow: hidden; 
  height: 400px; /* Adjust this height based on your tallest image */
}
.mySlides {
  position: absolute;
  opacity: 0;
  transition: opacity 3s ease-in-out;
}
.mySlides.active-img {
  opacity: 1;
} 
.business-img-wrap video {
  max-width: 100%;
}  
/* Instructions icon styles */
.instructions-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.instructions-icon i {
    color: #fff;
    font-size: 24px;
    cursor: pointer;
}

/* Instruction tooltip styles */
.instruction-tooltip {
    position: absolute;
    z-index: 5;
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: none;
}

.instruction-box {
    margin-bottom: 10px;
}

.instruction-box h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.instruction-box ul {
    list-style-type: none;
    padding: 0;
}

.instruction-box ul li {
    margin-bottom: 5px;
}

/* Show instruction tooltip when .show class is added */
.instruction-tooltip.show {
    display: block;
    top: 76%!important;
    left: 18%!important;
}
.dos-text{
    color: green;
}
.donts-text{
    color: red;
}
#backButton {
  position: absolute;
  z-index: 5;
  top: 10px; 
  left: 10px; 
  background: none;
  border: none;
  cursor: pointer;
}

.backIcon {
  width: 30px; 
  height: 30px; 
}
.margin-class{
  margin-bottom: 20%;
}
#try-on-section{
  margin-top: 10%!important;
  margin-bottom: 5%!important;
  display: none;
  transition: all 5s ease;
}
.canvas-container {
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
  overflow: hidden;
  max-width: 100%;
  /* position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden; */
}          
.tools {
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 100%;
  bottom: -21px;
  padding: 5px;
  border-radius: 0 0 10px 10px;
}
.tools button {
  background: none;
  border: none;
  cursor: pointer;
  /* margin: 2px; */
  margin-left: 25px;
}
.tools button img {
    width: 30px; 
    height: 30px; 
}
.soft-features-item{
  cursor: pointer;
}
/* splitter */
.base-canvas-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 4;
  width: 100%;
  height: 100%;
}
.base-canvas-container {
  position: absolute;
  z-index: 2;
  visibility: visible;
  overflow: hidden;
  width: 0;
  height: 100%;
  border-right: 5px solid #fff;
}
.splitter-handler {
  display: block;
  position: absolute;
  z-index: 99;
  top: 72%;
  left: 48%;
  width: 20px;
  height: 50px;
  border-radius: 4px;
  background: url('../img/algoface/splitter-direction.png') no-repeat #fff;
  background-size: contain;
  background-position: center;
}
.splitter-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.splitter-icon {
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 0 10px;
  cursor: pointer;
}

/* input range styles */
.splitter-range-container {
  position: absolute;
  top: 75%;
  width: 100%;
  z-index: 99;
}
.splitter-range-container input[type='range'] {
  -webkit-appearance: none;
  margin: 0 auto 15px;
  width: 100%;
  height: 7px;
  min-height: 7px;
  /*   background: rgba(255, 255, 255, 0.6); */
  background: transparent;
  border-radius: 5px;
  /*   background-image: linear-gradient(#ef3f72, #ef3f72); */
  background-image: none;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border: none;
}

/* Input Thumb */
.splitter-range-container input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: ew-resize;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
  outline: none;
}

.splitter-range-container input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: ew-resize;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
}

.splitter-range-container input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 50px;
  width: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: ew-resize;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
}

.splitter-range-container input[type='range']::-webkit-slider-thumb:hover {
  background: transparent;
}

.splitter-range-container input[type='range']::-moz-range-thumb:hover {
  background: transparent;
}

.splitter-range-container input[type='range']::-ms-thumb:hover {
  background: transparent;
}

/* Input Track */
.splitter-range-container input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.splitter-range-container input[type='range']::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.splitter-range-container input[type='range']::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
canvas#tryon-live-canvas-0 {
  position: absolute;
  height: 100%;
  z-index: 1;
  image-rendering:-webkit-optimize-contrast;
}
canvas#outputCanvas{
  position: absolute;
  /* width: 100% !important; */
  height: 100% !important;
  z-index: 1;
  image-rendering: -webkit-optimize-contrast;
    /* position: relative;
    width: 100%!important;
    height: 100%!important;
    border-radius: 5px;
    margin-top: 10px;
    z-index: 1; */
}
canvas#mask{
  position: absolute;
  height: 100%!important;
  z-index: 1;
  image-rendering: -webkit-optimize-contrast;
}
.business-img-wrap {
  max-width: 100%;
  height: auto;
}
.tabs {
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 5px;
}
.tablink {
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  width: 20%;
  font-weight: 500;
}
.tablink.active {
  background-color: #d3d0fb;
    color: white;
}
#selectedShadeName{
  font-size: larger;
}
/* Adjust the flexbox properties as needed */
#fps-section {
  display: flex;
  flex-wrap: wrap;
}

.fps-data {
  display: flex;
  flex-wrap: wrap;
}

.fps-data > div {
  flex: 0 0 33.3%; 
}
.fps-data > div >span {
  color: rebeccapurple;
}
/* CSS for responsiveness */
@media (max-width: 768px) {
.business-bg {
    padding: 105px 0 115px;
} 
.business-img-wrap {
    margin-bottom: 10px!important;
}
  .tools {
    position: absolute; 
    display: flex;
    justify-content: center;
  }
  .tools button{
    padding-left: 0;
  }
  .tools button img {
    width: 20px;
    height: 20px;
  }
}
/* Adjustments for mobile devices */
@media only screen and (max-width: 600px) {
  .tabs {
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
  }
  
  .tablink {
    display: inline-block;
    padding: 5px 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .color-palettes-container {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px; 
    -webkit-overflow-scrolling: touch;
  }
  
  .color-palette {
    display: inline-block;
    vertical-align: top;
  }

  .color-shade {
    margin-bottom: 5px;
  }

  .shade-color {
    width: 50px;
    height: 50px;
  }

  .shade-name {
    font-size: 12px;
  }
}

